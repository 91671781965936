import React, { useState, useCallback } from "react";
import Gallery from "react-photo-gallery";
import Carousel, { Modal, ModalGateway } from "react-images";


function GalleryView(props) {
  const [currentImage, setCurrentImage] = useState(0);
  const [viewerIsOpen, setViewerIsOpen] = useState(false);

  const openLightbox = useCallback((event, { photo, index }) => {
    setCurrentImage(index);
    setViewerIsOpen(true);
  }, []);

  const closeLightbox = () => {
    setCurrentImage(0);
    setViewerIsOpen(false);
  };

  const carouselStyles = {
    view: (base, state) => ({
      ...base,
      padding: '3%'
    }),
  }

  const modalStyles = {
    blanket: (base, state) => ({
      ...base,
      backgroundColor: "rgba(2, 0, 5, 0.85)",
      backdropFilter: "blur(5px) saturate(25%)",
    })
  }

  return (
    <React.Fragment>
      <Gallery onClick={openLightbox} {...props} />

      <ModalGateway target={window.document.body}>
        {viewerIsOpen ? (
          <Modal onClose={closeLightbox}
                 styles={modalStyles}>
            <Carousel
              currentIndex={currentImage}
              styles={carouselStyles}
              views={props.photos.map(x => ({
                ...x,
                src: x.src,
                source: x.source_sizes,
                caption: x.title
              }))}
            />
          </Modal>
        ) : null}
      </ModalGateway>

    </React.Fragment>
  );
}

export { GalleryView };

