import React from 'react';
import ReactDOM from 'react-dom';
import Gallery from "react-photo-gallery";
import Carousel, { Modal, ModalGateway } from 'react-images';
import { GalleryView } from 'gallery_view';

// Exports React, ReactDOM so they are available for invocation
window['__MIAOU__'] = {
  React: React,
  ReactDOM: ReactDOM,
  ReactPhotoGallery: Gallery,
  ReactImages: {
    Modal: Modal,
    ModalGateway: ModalGateway,
    Carousel: Carousel,
  },
  GalleryView: GalleryView,
}

